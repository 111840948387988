import React, {useEffect, useReducer, useState} from 'react';
import {useHistory, useLocation, useParams} from "react-router-dom";
import {initialState, reducer} from "./reducer";
import {useDefaultEndpoints} from "../../../../lib/api/useDefaultEndpoints";
import {toast} from "react-toastify";
import {Loader} from "../../../generic/loader";
import {TableCard} from "../../../generic/cards";
import {Button, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import qs from 'qs';

import tabList from "./tabList";
import {useForm} from "react-hook-form";
import {withBreadcrumbs} from "../../../common/hoc/Breadcrumbs";
import {ElementWithPermissions} from "../../../common/formElements";
import {ENTITIES, useRoleSystem} from "../../../../lib/utils/hooks/useRoleSystem";
import {t} from "i18next";
import {PRODUCT_TYPES} from "sv-common/constants/certificates";

const Certificate = withBreadcrumbs(({}) => {
    let history = useHistory()
    let {id} = useParams();
    const {pathname, search} = useLocation();
    const [state, dispatch] = useReducer(reducer, initialState);
    const {getEntry, createEntry, editEntry} = useDefaultEndpoints('certificates')
    const [isLoading, setLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [isEntryCreated, setIsEntryCreated] = useState(false);
    const getOrders = useDefaultEndpoints('orders').getEntries
    const [activeTab, setActiveTab] = useState("general");
    const permissions = useRoleSystem(ENTITIES.CERTIFICATES);

    const toggleTab = tab => {
        if (activeTab !== tab.url) {
            setActiveTab(tab.url)
            history.replace(`${pathname}?t=${tab.url}`)
        }
    };
    const form = useForm({reValidateMode: 'onBlur',});

    useEffect(() => {
        if (id)
            getEntry(id).then(certificate => {
                getOrders({certificate_id: id, master_order_null: true}).then(order => {
                    dispatch({type: 'setForm', payload: {...certificate, order: order[0], product_type: PRODUCT_TYPES.UNIVERSAL}});
                    setLoading(() => false);
                })
            })        // eslint-disable-next-line
        else {
            form.reset({assignee_email: '', assignee_name: '', assignee_surname: '', product_type: PRODUCT_TYPES.UNIVERSAL})
            dispatch({type: 'refreshFields'});
            setLoading(() => false);
        }

        if (!search) history.replace(`${pathname}?t=${activeTab}`);
        let {t} = qs.parse(history.location?.search.slice(1))
        if (t)
            setActiveTab(t)

        // eslint-disable-next-line
    }, [history.location.pathname, id])


    const {handleSubmit} = form;
    const createOrUpdateCertificate = () => {
        setIsSaving(true)
        const cleanEmails = {
            assignee_email: state.assignee_email.trim().toLowerCase(),
            senders_email: state.senders_email?.trim()?.toLowerCase()
        }
        if (state.id){
            const {size_left, assignee_email, senders_email, ...cleanState} = state
            editEntry(state.id, {...cleanState, ...cleanEmails}).then(() => {
                toast.success(t('management.certificates.certificateIndex.success'))
            }).finally(() => {
                setIsSaving(false)
            })
        }
        else {
            setIsEntryCreated(true)
            if (!isEntryCreated) {
                createEntry({...state, ...cleanEmails}).then(res => {
                    history.push('/administration/certificates/edit/' + res.id)
                }).catch(() => {
                    setIsEntryCreated(false)
                })
            }
            setIsSaving(false)
        }
    }

    const onSubmit = handleSubmit((data) => createOrUpdateCertificate())

    return isLoading
        ? <Loader/>
        : <TableCard>
            <Row className={'mb-3 justify-content-between align-items-center'}>
                <Col className={'col-sm-auto col-12 flex-grow-1 col mb-3 mb-sm-0'}>
                    <div className={'display-3'}>
                        {id ? t('management.certificates.certificateIndex.editing') : t('management.certificates.certificateIndex.creating')}{' '}
                        {t('management.certificates.certificateIndex.certificate')}
                    </div>
                </Col>
                <Col className={'col-auto'}>
                    <ElementWithPermissions disabled={!permissions.edit || isSaving}>
                        <Button className={'btn-rounded'} color={'success'} size={'sm'} onClick={onSubmit}>
                            {t('management.certificates.certificateIndex.save')}
                        </Button>
                    </ElementWithPermissions>
                </Col>
                <Col className={'col-auto'}>
                    <Button className={'btn-rounded ml-1'} color={'secondary'} size={'sm'}
                            onClick={() => history.push('/administration/certificates')}>
                        {t('management.certificates.certificateIndex.cancel')}
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Nav tabs fill className={'align-items-stretch no-gutters'}>
                        {tabList({state, dispatch})
                            .filter(tab => tab.isDisplayed !== false)
                            .map(tab =>
                                <NavItem
                                    className={`col-sm-12 col-md-${Math.ceil(10 / tabList({state, dispatch}).length)}`}
                                    key={`field-tabs-${tab.name}`}>
                                    <NavLink className={"h-100 " + (activeTab === tab.url && "active" ? "active" : "")}
                                             onClick={() => toggleTab(tab)}>
                                        {tab.name}
                                    </NavLink>
                                </NavItem>
                            )}
                    </Nav>
                </Col>
            </Row>

            <Row>
                <Col>
                    <TabContent activeTab={activeTab}>
                        {tabList({state, dispatch, form})
                            .filter(tab => tab.isDisplayed !== false)
                            .map(tab => (
                                <TabPane tabId={tab.url} key={tab.name}>
                                    {tab.child}
                                </TabPane>
                            ))}
                    </TabContent>
                </Col>
            </Row>

        </TableCard>
})

export default Certificate;
