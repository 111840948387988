import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types'
import {TableCard} from "../../generic/cards";
import {useDefaultEndpoints} from "../../../lib/api/useDefaultEndpoints";
import {DataTable, field} from "../../generic/tables";
import {FaInfoCircle, FaPlus} from 'react-icons/fa';
import {FormGroup, Input, Label, PopoverBody, UncontrolledPopover} from "reactstrap";
import {useHistory} from 'react-router-dom';
import {ConditionalField, TextSearch} from "../../generic/fields";
import {datetime} from "../../../lib/utils/format";
import {openInNewTab} from "../../../lib/utils/helper";
import {withBreadcrumbs} from "../../common/hoc/Breadcrumbs";
import {extractNumbers} from "sv-common/functions/formatters";
import i18next from "i18next";
import { localizeRoleName, localizeRoles } from '../../../lib/utils/helpers/localizeFeatures';
import getCountryValue from "../../../lib/utils/getCountryValue";

const Clients = withBreadcrumbs(({role = undefined, onClick = undefined, isInline, canCreate = true, showSurname = false, customFilters = {}}) => {
    const {findAndCountEntries} = useDefaultEndpoints('clients');
    const {getEntries} = useDefaultEndpoints('users-permissions/roles');
    const [roles, setRoles] = useState([]);
    const [ownerFilter, setOwnerFilter] = useState();

    const [selectedRole, setSelectedRole] = useState(role === 'all' ? undefined : role);
    let history = useHistory();

    const {t, language} = i18next;

    useEffect(() => {
        getEntries({}).then(res => setRoles(() => localizeRoles([...res.roles], language)))
    }, [])


    const commonFilters = (filters) => [
        ...(selectedRole ? [{'role_name': selectedRole}] : ''),
        {user_data_null: false},
        ...filters
    ]

    const filters = {
        _where: {
            _or: [
                [...commonFilters([{'full_name_search_contains': ownerFilter}])],
                [...commonFilters([{'first_name_contains': ownerFilter}])],
                [...commonFilters([{'clean_phone_contains': ownerFilter}])],
                [...commonFilters([{'email_contains': ownerFilter}])],
                ...(extractNumbers(ownerFilter)
                    ? [{...commonFilters([{'phone_contains': extractNumbers(ownerFilter)}])}]
                    : []),
                ...(extractNumbers(ownerFilter)
                    ? [{...commonFilters([{'clean_phone_contains': extractNumbers(ownerFilter)}])}]
                    : []),
            ],
        },
    };

    const onMiddleClick = (res) => openInNewTab('/administration/users/edit/' + res.id)

    const currentFields = [
        field(showSurname ? t('management.users.nameAndSurname') : t('management.users.name'), true, "first_name", res => showSurname ? <>{res.first_name} {res.last_name}</> : res.first_name),
        field(t('management.users.mail'), true, "email"),
        field(t('management.users.phone'), false, "clean_phone"),
        field(t('management.users.role'), true, "role_name", res => localizeRoleName(res.user_data.role.description, language)),
        field(t('management.users.blocked'), true, "is_blocked", res =>
            <small>{res.is_blocked ? t('management.users.yes') : t('management.users.no')}</small>
        ),
        field(t('management.users.registered'), true, "created_at", res => datetime(res.created_at))
    ]

    const countryFields = getCountryValue({'ru': [
            field(t('management.users.insured'), true, "insured")
        ]})

    countryFields && currentFields.push(...countryFields)

    return <>
        <TableCard isInline={isInline}>
            <div className="row mb-4">
                {!role && <div className={'col-12 col-md-3'}>
                    <FormGroup>
                        <Label>
                            {t('management.certificates.list.ownerInputLabel')}
                        </Label>
                        <Input
                            type={'select'}
                            id="ChooseRole"
                            className="form-control"
                            name="role"
                            value={selectedRole || ''}
                            onChange={(e) => setSelectedRole(() => e.target.value)}
                            bsSize={'sm'}
                        >
                            <option key={"user-role-none"} value='' disabled>{t('management.users.chooseRole')}</option>
                            {roles && roles.map(role => {
                                return <option key={"user-role-" + role.id}
                                               value={role.description}>{role.description}</option>
                            })
                            }
                        </Input>
                    </FormGroup>
                </div>}
                <div className={'col-12 col-md-3'}>
                    <FormGroup>
                        <Label>
                            {t('management.certificates.list.ownerInputLabel')}
                            <FaInfoCircle id={"ownerInput"} className="ml-1"/>
                            <UncontrolledPopover placement="right" target={"ownerInput"} trigger={'hover'}>
                                <PopoverBody>
                                    {t('management.certificates.list.ownerInputPhoneHint')}<br/>
                                    {t('management.certificates.list.ownerInputNameHint')}
                                </PopoverBody>
                            </UncontrolledPopover>
                        </Label>
                        <TextSearch placeholder={t('management.certificates.list.ownerInputPlaceholder')}
                                    value={ownerFilter || ''} onSearch={e => setOwnerFilter(() => e)} />
                    </FormGroup>
                </div>
                <ConditionalField condition={canCreate}>
                    <div className={"col-12 col-md-6"}>
                        <div className="d-flex justify-content-end">
                            <div className={'btn btn-rounded btn-primary btn-sm'}
                                 onClick={() => history.push(`/${history.location.pathname.split('/')[1]}/users/edit`)}>
                                <FaPlus/>
                                &nbsp; {t('management.users.create')}
                            </div>
                        </div>
                    </div>
                </ConditionalField>
            </div>
            <DataTable findAndCount={findAndCountEntries}
                       filters={{...filters, ...customFilters}}
                       fields={currentFields}
                       sortField="created_at"
                       sortDir="desc"
                       name="clients-list"
                       onClick={client => onClick ? onClick(client) : history.push(`/${history.location.pathname.split('/')[1]}/users/edit/` + client.id)}
                       onMiddleClick={client => !onClick && onMiddleClick(client)}
            />
        </TableCard>
    </>
})

Clients.propTypes = {
    onClick: PropTypes.func,
    role: PropTypes.string,
    canCreate: PropTypes.bool
};

export default Clients;

