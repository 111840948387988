export const ru = {
    directories: {
        subscriptions: {
            name: "Название",
            country: "Страна",
            price: "Стоимость",
            active: "Активен",
            commonDiscount: "Общая скидка",
            individualDiscount: "Индивидуальная скидка",
            indivDiscount: "Индив. скидка",
            raceDiscount: "Скидка гонки",
            sailGPDiscount: "Скидка на Гран-При яхтинг",
            editModalChildren: {
                nameInputLabel: "Имя",
                nameInputPlaceholder: "Абонемент 3000",
                keyInputLabel: "Ключ",
                keyInputValidation: "Допустимы: латиница, цифры, '_' и '-'",
                raceDiscount: "Скидка на гонки",
                sailGPDiscount: "Скидка на Гран-При яхтинг",
            }
        },
        directoryTemplate: {
            create: "Создать",
            search: {
                coordinator: 'Поиск по куратору',
                base_addresses: 'Поиск адреса базы',
                areas: 'Поиск по площадкам',
                formats: 'Поиск по форматам',
            }
        },
        breadcrumbs: {
            subscriptions: "Абонементы",
            directories: "Справочники",
            difficultyLevels: "Шкалы сложности",
            userFields: "Пользовательские поля",
            userFieldsTemplates: "Шаблоны полей",
            travelOptions: "Опции приключения",
            currencies: "Валюты",
            boatsClasses: "Классы лодок",
            shirtSizes: "Размеры футболок",
            publicContracts: "Оферты",
            extraMaterials: "Доп. материалы",
            legalEntities: "Юр. лица",
            checks: "Чеки",
            corpClients: "Корп. клиенты",
            transactionStatus: "Статусы транзакций",
            coordinators: "Кураторы",
            baseAddresses: "Адреса баз"
        },
        difficultyLevels: {
            level: "Уровень",
            briefly: "Кратко",
            description: "Описание",
            group: "Группа",
            descriptionPlaceholder: "Тут описание...",
            difficultyLevel: "Уровень сложности",
            maxLevel: "Макс. уровень",
            chooseTag: "Выберите тэг",
        },
        userFields: {
            fieldName: "Название поля",
            required: "Обязательно к заполнению",
            optionalToShow: "Опционально к показу",
            nameEn: "Название (en)",
            nameEnHint: "без пробелов, через _",
            nameRu: "Название (ru)",
            nameRuPlaceholder: "пример",
            requiredLabel: "Обязательно к заполнению",
            optionalToShowLabel: "Опционально к показу",
        },
        userFieldsTemplates: {
            templateName: "Название шаблона",
            name: "Название",
            includedFields: "Включенные поля",
            includedFieldsPlaceholder: "Выберите опции",
        },
        currencies: {
            name: "Обозначение",
            symbol: "Знак"
        },
        boatsClasses: {
            boatClass: "Класс лодки",
            name: "Название",
            boatPlaces: "Места на лодке",
            isActive: "Активна",
        },
        shirtSizes: {
            sizeNumbers: "Размер (цифры)",
            sizeLetters: "Размер (буквы)",
        },
        publicContracts: {
            link: "Ссылка",
            name: "Название",
            namePlaceholder: "Правила оплаты."
        },
        legalEntities: {
            name: "Название",
            shopId: "ID магазина платежной системы",
            apiKey: "Ключ API платежной системы",
            subscriptionAvailable: "Работа для абонементов",
            blrApplicable: "Работа в РБ",
            certificateType: "Тип сертификата",
            paymentSystem: "Платежная система",
            namePlaceholder: "Пинигин",
            shopIdLabel: "ID магазина",
            tax: "Код налога",
            shopIdHint: "Для терминалов DEMO можно дописать APPROVED",
            apiKeyLabel: "Ключ API",
            orangeData: "Данные для OrangeData",
            inn: "ИНН",
            privateKey: "Приватный ключ",
            certificate: "Сертификат",
            certificateKey: "Ключ сертификата",
            chooseType: "Выберите тип",
            insuranceId: "Id Пульс",
            insuranceIdPlaceholder: "Добавьте Id Пульс",
            installment: {
                label: 'Рассрочки',
                types: 'Виды рассрочек',
                promocode: 'Промокод',
                markup: 'Наценка (%)',
                duration: 'Продолжительность (Месяцев)',
                showcaseId: 'Showcase ID',
                shopId: 'Shop ID',
                passId: 'Pass ID',
            }
        },
        checks: {
            name: "Название",
            type: "Тип чека",
            namePlaceholder: "Греческие каникулы",
            chooseType: "Выберите тип",
            varToUse: "Вы можете использовать переменные:",
            baseVarInfo: " - в чек будет поставлена база события ",
            yachtVarInfo: " - в чек будет поставлена лодка ",
            example: "Пример: Тренировка на ",
            exampleRes: "Отобразится в чеке: Тренировка на ",
        },
        transactionStatus: {
          title: 'Название',
          type: 'Тип'
        }
    },
    school: {
        theory: {
            groupsList: {
                deleteGroup: "Удаление группы",
                exactlyDeleteGroup: "Точно удалить?",
                noDeleteGroup: "Нет, не нужно",
                yesDeleteGroup: "Да, все правильно",
                newGroupTheory: "Новая группа (теория)",
                captainSchool: "Школа капитанов",
                gims: "Гимс",
                moscow: "Москва",
                products: "Продукты:",
                recruiting: "Набираются",
                learning: "Обучаются",
                classes: "Занятия:",
                all: "Все",
                closest: "Ближайшие",
                archive: "Архив",
                group: "Группа",
                groupSettings: "Настройки группы",
                copyGroup: "Копировать группу",
                place: "Где проходит",
                lessonsPerWeek: "Занятий в неделю",
                customSchedule: "Расписание вручную",
                intensive: "интенсив",
                lec: "лек.",
                nextLesson: "Следующее занятие",
                teacher: "Преподаватель",
                noPicked: "Не выбран",
                bookedToTotal: "Записи",
                newGroup: "Новая группа",
                groups: "Группы:",
            },
            tabList: {
                aboutArrival: "О заезде",
                clients: "Покупатели",
                globalSales: "Распродажа",
            },
            mainInfo: {
                brieflyAboutGroup: "Кратко про группу",
                lessonsPerWeek: "зан. в неделю"
            },
            lessonsTable: {
                lessons: "Занятия",
                add: "Добавить",
                date: "Дата",
                start_time: "Время",
                theme: "Тема занятия",
                deleteLesson: "Удалить занятие",
                youDeleteLesson: "Вы удаляете занятие:",
                isEndTimeCheck: "Закрывать регистрацию на мероприятие по дате окончания?",
            },
            editModalChildren: {
                lessonStart: "Старт занятия",
                lessonEnd: "Конец занятия",
                lessonNumber: "Номер занятия",
                meeting: "Знакомство",
                product: "Продукт:",
                school: "Школа",
                gims: "Гимс",
                intensive: "Гимс",
                moscow: "Москва",
                online: "Онлайн",
                address: "Адрес",
                totalSits: "Размер группы",
                schedule: "Расписание",
                startDate: 'Старт группы',
                pickTeacher: 'Выбрать преподавателя',
                pickTeachers: 'Выбрать доп. преподавателей',
                tgLink: 'Телеграм канал',
                platform: 'Платформа',
                platformCode: 'Код платформы',
                promocodesActive: 'Промокоды активны',
                checkAge: 'Подтверждение возраста',
                age: 'Возраст',
                quizLink: 'Ссылка на анкету',
                calendarUrl: 'Ссылка на календарь',
                extraMaterials: 'Доп. материалы',
                place: 'Где проходит',
                price: 'Стоимость',
                additionalInfo: 'Доп. материалы',
                paidLocally: 'Оплата наличными',
                tg_options: 'Телеграм',
            },
            edit: {
                travelName: "Название приключения",
                save: "Сохранить",
                cancel: "Отменить",
                groupMain: "Главное о группе",
            },
            techInfo: {
               placeAmount: "Всего мест",
               price: "Стоимость",
               groupURL: "URL группы",
               telegramLink: "Ссылка на телеграм канал ТГ",
               platformLink: "Ссылка на платформу",
               platformCode: "Код платформы",
               quizLink: "Ссылка на анкету",
               calendarLink: "Ссылка на календарь",
            },
            viewTeacherModal: {
                pickTeacher: "Выберите преподавателя",
                pickTeachers: 'Выбрать доп. преподавателей',
                close: "Закрыть",
            },
            addressBlock: {
                pickTeacher: "Выбрать преподавателя",
                pickTeachers: 'Выбрать доп. преподавателей',
                place: "Где проходит",
                moscow: "Москва",
                online: "Онлайн",
                address: "Адрес",
                coordinates: "Координаты",
            },
            switchBlock: {
                isOpen: "Запись открыта",
                isPrivate: "Запись только по ссылке",
                isInstallment: 'Включить рассрочки',
                ageRequired: "Подтверждение возраста",
                age: "Возраст",
            },
            additionalInfo: {
                additionalLinks: "Ссылки на дополнительные материалы"
            },
            cities: {
                online: "Онлайн",
                moscow: "Москва",
                saintPetersburg: "Санкт-Петербург",
                minsk: "Минск",
                nizhniyNovgorod: "Нижний Новгород",
                turkey: "Турция",
                portRashid: "Порт Рашид, Дубай",
                sochi: "Сочи",
                yaroslavl: "Ярославь",
                kazan: "Казань",
                vladivostok: "Владивосток",
            }
        },
        practices: {
            gridType: 'Вид сетки:',
            events: 'События',
            arrivals: 'Заезды',
            classes: 'Занятия:',
            closest: 'Ближайшие',
            all: 'Все',
            group: 'Группа',
            arrivalSettings: 'Настройки заезда',
            country: 'Где происходит',
            dates: 'Даты',
            bookedToTotal: 'Записи',
        },
        breadcrumbsAndTitle: {
            theory: 'Теория',
            lecture: 'Лекторий',
            practice: 'Практика',
            creating: 'Создание',
            editing: 'Редактирование',
        }
    },
    bases: {
        events: {
            newEventBlocks: {
                promocodes: {
                    title: 'Промокоды',
                    existing: 'Существующий',
                    promocodesFor: 'Промокоды для',
                    new: 'Новый',
                    active: 'активны',
                    name: 'Имя',
                    size: 'Размер',
                    notSpecified: 'не указан',
                    expiresAt: 'Действителен',
                    before: 'До',
                    indefinitely: 'Неогр.',
                    bases: 'базы',
                    practices: 'практики',
                    slots: 'слота',
                    events: 'события',
                    travels: 'приключения',
                    practiceArrival: 'заезда практики',
                    arrival: 'заезда',
                    groups: 'группы',
                    camps: 'лагеря',
                },
                pickEventType: 'Выберите тип события',
            },
            newEvent: 'Новое событие',
            corpClient: 'Корп. клиент',
        },
        base: {
            reloadSuccess: 'Обновление успешно!',
            somethingWentWrong: 'Что-то пошло не так!',
            requiredError: 'Не заполнены все обязательные поля',
            baseName: 'Название базы',
            save: 'Сохранить',
            cancel: 'Отменить',
            about: 'О базе',
            instructors: 'Инструкторы',
            administration: 'Управление',
            baseSearch: 'Поиск базы',
            create: 'Создать',
            name: 'Название',
            phone: 'Телефон',
            link: 'Ссылка',
            country: 'Страна',
            city: 'Город',
            order: 'Порядок',
            open: 'Открыто',
            boats: 'Лодки',
            deleteClass: 'Удалить класс яхт?',
            deleteClassText: 'Если лодка временно вышла из строя, для аналитики будет лучше уменьшить количество доступных лодок',
            no: 'Нет, не нужно',
            yes: 'Да, все правильно',
            attachedBoats: 'Прикрепленные лодки',
            boatClass: 'Класс лодки',
            availableToday: 'Доступно сегодня',
            delete: 'Удалить',
            boatClassPlaceholder: 'Класс >9000',
            pickClass: 'Выберите класс',
            amount: 'Количество',
            linkHowToGet: 'Ссылка "как добраться',
            hideToTime: 'Скрывать за время',
            paidLocally: 'Оплата на месте',
            cover: 'Обложка (900х380 px)',
            fileSizeMax: 'Размер файла не более 3 мб',
            street: 'Улица',
            streetEx: 'Пушкина',
            house: 'Дом',
            index: 'Индекс',
            coords: 'Координаты',
            addInfo: 'Доп. информация',
            firstPier: '1 причал',
            deleteBase: 'Удалить базу?',
            deleteBaseInfo: 'Удаление базы приведет к ее архивации - она пропадет из списка доступных',
            main: 'Главное',
            namePlaceholder: 'Строгино',
            nameLabel: 'Имя отображается и в админке, и у пользователя',
            baseUrl: 'URL базы',
            baseUrlPlaceholder: 'URL-адрес отображается и в админке, и у пользователя; не допускаются символы "/" и "_"',
            invalidSymbol: 'Введен недопустимый символ',
            baseOrder: 'Порядок базы',
            baseOrderPlaceholder: 'Порядок повлияет на выдачу и в админке, и у пользователя',
            bookAddress: 'Адрес букинга',
            bookAddressHint: 'Значение по умолчанию - ',
            baseWork: 'Работа базы',
            openedSeason: 'Сезон открыт',
            deleteBaseButton: 'Большая кнопка чтобы удалить базу',
            deleteBaseText: 'Удалить базу',
            archiveBase: 'База отправится в архив вместе со всей историй заказов',
            cantCancel: 'Это действие нельзя отменить',
            events: 'События',
            slots: 'Слоты',
            showHidden: 'Показывать скрытые',
            dateFilter: 'Фильтр по дате',
            practiceSv20: 'Практика SV-20',
            trainSv20: 'Тренировка на SV-20',
            date: 'Дата',
            statusReloaded: 'Статус обновлен',
            slotDeleting: 'Удаление слота',
            areYouSureSlot: 'Вы точно хотите удалить слот ?',
            time: 'Время',
            orders: 'Записи',
            eventDeleting: 'Удаление события',
            areYouSureEvent: 'Вы точно хотите удалить событие ?',
            createEvent: 'Создать событие',
            comment: 'Комментарий',
            duration: 'Длительность',
            brieflyAboutEvent: 'Кратко про событие',
            go: 'перейти...',
            hideExpired: 'Скрывать прошедшие',
            add: 'Добавить',
            haveNoTrainer: 'Тренер не назначен',
            showPromo: 'Показывать промокоды',
            startDate: 'Время начала',
            endDate: 'Время конца',
            repetitionRate: 'Частота повторений',
            pickRepetitionRate: 'Выберите частоту',
            once: 'Однократно',
            daily: 'Ежедневно',
            weekly: 'Еженедельно',
            monthly: 'Ежемесячно',
            yearly: 'Ежегодно',
            pickInstructors: 'Выберите инструкторов',
            general: 'Основное',
            eventType: 'Тип события',
            luch: 'Луч',
            pickLuch: ' (нужно выбрать Луч)',
            pickLaser: ' (нужно выбрать Laser)',
            boatLimit: 'Лимит лодок',
            price: 'Стоимость',
            settings: 'Настройки',
            registerWithoutInstructor: 'Можно записаться без инструктора',
            rentFullBoat: 'Бронь лодки целиком',
            instructorSelector: 'Показывать выбор инструктора',
            subscriptionPay: 'Можно оплатить абонементом',
            onlyTeam: 'Запись только командой',
            onlyLink: 'Доступ только по ссылке',
            slotClosed: 'Слот закрыт',
            eventClosed: 'Событие закрыто',
            checkAge: 'Подтверждение возраста',
            hideUntil: 'Скрывать за время',
            age: 'Возраст',
            color: 'Цвет события в календаре',
            sum: 'Сумма',
            percent: 'Процент',
            discountText: 'Текст скидки',
            discountTextPlaceholder: 'Очень важный комментарий',
            discounts: 'Скидки',
            coachlessDisc: 'Скидка за бронь без инструктора',
            fullbookDisc: 'Скидка за бронь лодки целиком',
            singleBoat: 'Скидка не распространяется на одноместные лодки',
            fullbookUnavailable: 'Бронь целиком недоступна',
            teamDisc: 'Скидка за бронь целой командой',
            type: 'Тип',
            infoAndLinks: 'Инфо и ссылки',
            eventComment: 'Комментарий к событию',
            eventCommentHint: 'Одна-две строки мелким кеглем, 60 символов',
            eventUrl: 'URL события',
            eventUrlHint: 'Знак \'/\' недопустим',
            decs: 'Пояснение',
            decsHint: 'Не больше 2 строк текста, 250 символов',
            decsPlaceholder: 'Пример пояснения',
            bringGuide: 'Что взять с собой на тренировку',
            publicContract: 'Оферта',
            pickContract: 'Выберите контракт',
            returnPolicy: 'Условия возврата и отмены',
            deleteEvent: 'Удалить событие',
            deleteEventInfo: 'Удаление события не затронет созданные слоты',
            extraMaterials: 'Ссылки на дополнительные материалы',
            slotComment: 'Комментарий к слоту',
            slotStatus: 'Статус слота',
            coming: 'Грядущий',
            postponed: 'Перенесен',
            canceled: 'Отменен',
            finished: 'Завершен',
            other: 'Иное',
            slotPrice: 'Цена слота',
            sick: 'Болеет',
            busy: 'Занят',
            inactive: 'Неактивный',
            active: 'Активный',
            instructor: 'Инструктор',
            status: 'Статус',
            yachtsClasses: 'Классы яхт',
            goToSlot: 'Перейти в слот',
            attachInstructor: 'Инструктор на другой базе. Прикрепить его?',
            instructorBusy: ' Инструктор занят в заказе на другом слоте: ',
            instructorBusyOtherSlot: 'Инструктор в этой базе, на другом слоте. Прикрепить его?',
            instructorStatus: 'Состояние инструктора',
            attach: 'Прикрепить',
            pickInstructor: 'Выберите инструктора',
            close: 'Закрыть',
            URLCopied: 'URL скопирован',
            syncData: 'Синхронизированы данные',
            loading: 'загрузка',
            remained: 'осталось',
            users: 'Пользователи',
            promocodes: 'Промокоды',
            slot: 'Слот:',
            durationPlaceholder: '2ч 30м',
            slotLowercase: 'слот',
            singleBooking: 'Одиночная запись',
            disablePromo: 'Отключить сертификаты и промокоды',
            reserved: 'забронировано',
            tg_options: 'Телеграм',
            shot_in_admin_tooltip: 'Если кнопка активна, то база не отображается в общем списке баз, если кнопка неактивна, то база отображается в общем списке баз',
            deleteInstructor: 'Удалить инструктора?',
            confirm: 'Подтвердить',
            showBookingLimit: 'Показывать ограничение при бронировании',
            instructorIsBooked: 'Инструктор забронирован. Для удаления инструктора из слота необходимо произвести замену инструкторов в заказах ниже.',
        },
        breadcrumbsAndTitle: {
            bases: 'Базы',
            calendar: 'Календарь',
            events: 'События',
            editing: 'Редактирование',
            new: 'Новое',
            newBase: 'Новая база',
        }
    },
    globalSettings: {
        breadcrumbs: {
            global_settings: 'Глобальные настройки',
            separate_functionality: 'Отдельный функционал'
        },
        separateFunctionality: {
            enableCustomerInsurance: 'Включить страхование клиентов',
            enablePartialPayment: 'Включить частичную оплату',
            enableInstallment: 'Включить рассрочки'
        }
    },
    adventures: {
        adventuresList: {
            deleteTravel: 'Архивировать путешествие',
            areYouSure: 'Вы уверены?',
            sure: 'Конечно!',
            current: 'Текущие',
            past: 'Прошедшие',
            archive: 'Архив',
            all: 'Все',
            russia: 'Россия',
            gastroRegata: 'Гастро-регата',
            date: 'По дате',
            load: 'По загрузке',
            world: 'Мир'
        },
        adventureCard: {
            copyAdventure: 'Копировать путешествие',
            editAdventure: 'Редактировать путешествие',
            deleteAdventure: 'Удалить путешествие',
            january: 'Январь',
            february: 'Февраль',
            march: 'Март',
            april: 'Апрель',
            may: 'Май',
            june: 'Июнь',
            july: 'Июль',
            august: 'Август',
            september: 'Сентябрь',
            october: 'Октябрь',
            november: 'Ноябрь',
            december: 'Декабрь',
        },
        arrival: {
            title: 'Заезд:',
            updateSuccess: 'Обновление успешно!',
            somethingWentWrong: 'Что-то пошло не так!',
            dataWasSync: 'Синхронизированы данные',
            arrivalName: 'Название заезда',
            save: 'Сохранить',
            addRelated: 'Добавить связанный',
            no: 'Нет',
            relatedArrival: 'Связанный заезд:',
            left: 'осталось',
            load: 'загрузка',
            about: 'О заезде',
            dates: 'Даты заезда',
            reserved: 'забронировано'
        },
        tabList: {
            clients: "Покупатели",
            aboutArrival: "О заезде",
            promocodes: "Промокоды",
            globalSales: "Распродажа",
        },
        mainInfo: {
            brieflyAbout: 'Кратко про',
            practice: 'практику',
            travel: 'приключение',
            basePrice: 'Базовая цена:',
        },
        arrivalsTable: {
            thereIsPayed: 'На заезд есть оплаченные места! Точно удалить?',
            areYouSure: 'Точно удалить?',
            arrivalDeleting: 'Удаление заезда',
            no: 'Нет, не нужно',
            yes: 'Да, все правильно',
            arrivals: 'Заезды',
            add: 'Добавить',
            hideExpired: 'Скрывать прошедшие',
            arrival: 'Заезд',
            name: 'Имя',
            price: 'Цена',
            bookedToTotal: 'Записи',
            link: 'Ссылка',
            status: 'Статус'
        },
        statusIndicator: {
            title: 'Перед просмотром статуса – обнови страницу!',
            green: 'запись на заезд открыта',
            yellow: 'запись на заезд открыта только по ссылке',
            red: 'запись на заезд закрыта или на него кончились места'
        },
        editArrival: {
            name: 'Название',
            date: 'Дата',
            repeat: 'Частота повторений',
            pickRepeat: 'Выберите частоту',
            once: 'Однократно',
            daily: 'Ежедневно',
            weekly: 'Еженедельно',
            monthly: 'Ежемесячно',
            yearly: 'Ежегодно',
        },
        edit: {
            name: 'Название',
            practice: 'практического занятия',
            travel: 'приключения',
            save: 'Сохранить',
            cancel: 'Отменить',
            main: 'Главное',
            legalEntity: 'Юр.лицо для оплаты',
            pickLegalEntity: 'Выберите юр. лицо',
            totalSits: 'Всего мест',
            price: 'Стоимость',
            urlHint: 'Знак \'/\' недопустим',
            arrival: 'заезда',
            partialPayments: 'Варианты частичной оплаты',
            coordinates: 'Координаты места сбора',
            legalEntities: 'Оферта',
            pickContract: 'Выберите контракт',
            paymentDesc: 'Описание для платежки (en)',
            paymentHint: 'Макс. длина 100 символов. Применяется только для RBK',
            pickCheck: 'Выберите чек',
            check: 'Чек',
            open: 'Запись открыта',
            private: 'Запись только по ссылке',
            checkAge: 'Подтверждение возраста',
            age: 'Возраст',
            currencyOnly: 'Можно платить только валютой',
            showRestrictions: 'Отображать ограничения',
            loyalDiscount: 'Скидка 50 евро постоянным клиентам',
            hasTransfer: 'Предлагать трансфер',
            pickPrice: 'Выберите валюту',
            currency: 'Валюта',
            teamBookDiscount: 'Скидка за бронь командой',
            sum: 'Сумма',
            percent: 'Процент',
            peopleCount: 'Кол-во человек',
            type: 'Тип',
            askAlways: 'Спрашиваем всегда',
            field: 'Поле',
            show: 'Показ',
            whatWeNeed: 'Что нам нужно от клиента',
            template: 'Шаблон',
            pickTemplate: 'Выберите шаблон',
            arrivalCard: 'Карточка заезда',
            travelCard: 'Карточка приключения',
            imgSize: 'Обложка (900х380 px)',
            fileSize: 'Размер файла не более 3 мб',
            difficulty: 'Сложность',
            pickDifficulty: 'Выберите сложность',
            averageTime: 'В среднем часов в день',
            timeOnWaterFrom: 'Время на воде ОТ',
            timeOnWaterTo: 'Время на воде ДО',
            airTemperature: 't° воздуха',
            waterTemperature: 't° воды',
            arrivalInfo: 'Информация о поездке',
            whatInclude: 'Что включено',
            whatIncludePlaceholder: 'гамак\nтапки',
            whatNotInclude: 'Что не включено',
            whatNotIncludePlaceholder: 'перелет\nпроживание',
            restrictions: 'Ограничения',
            restrictionsPlaceholder: 'Обратите внимание: из-за государственного регламента посещения Белого моря это приключение пока что доступно только для граждан РФ',
            insideInfo: 'Внутренняя информация',
            arrivalComment: 'Комментарий к заезду',
            travelComment: 'Комментарий к приключению',
            commentHint: 'Одна-две строки мелким кеглем, 120 символов',
            arrivalClientComment: 'Комментарий для клиентов к заезду',
            travelClientComment: 'Комментарий для клиентов к приключению',
            checkInfo: 'Данные по чеку',
            agentSign: 'Признак агента',
            pickAgentSign: 'Выберите признак агента',
            another: 'Иной',
            supplierName: 'Наименование поставщика',
            supplierPhone: 'Телефон поставщика',
            supplierPhoneHint: 'В формате +70001112233',
            supplierINN: 'ИНН Поставщика',
            supplierINNHint: 'Только цифры, без разделителей',
            additionalLinks: "Ссылки на дополнительные материалы",
            tg_options: 'Телеграм',
            isInstallment: 'Включить рассрочки',
        }
    },
    generic: {
        title: "Главное меню",
        pager: {
            entriesPerPage: "Записей на странице",
                of: "из"
        },
        cancelButton: "Отмена",
        okButton: "Ок",
        exportButton: {
            exportMaster: 'только мастер-заказы',
            exportWithCompanion: 'заказы с попутчиками',
            export: "Экспорт",
            allTable: "Вся таблица",
            currentPage: "Текущая страница",
            loading: "Загрузка...",
            ok: "Ок",
            uploadFile: "Файл выгрузки",
            downloadLater: "Выгрузка требует больше времени. Вы можете скачать файл позднее в разделе выгрузок",
            downloadLaterShort: "Вы можете скачать его позже в разделе выгрузок",
            discharge: "Выгрузка",
            exportByBase: "Экспорт всех покупок по базе",
            exportByBaseTooltip: "Экспорт покупок по всем слотам во всех событиях в базе",
            exportByAdventureDirection: "Путешествия",
            exportByGroupDirection: "Школа",
            exportByEventDirection: "Спорт",
            exportOrdersByDirection: "Экспорт покупок"
        },
        canselButton: "Отмена",
        modals: {
            ModalConfirmation: {
                cancel: "Отменить"
            }
        },
        datePicker: {
            date: 'Дата'
        },
        editModal: {
            edit: 'Редактирование',
            create: 'Создание'
        },
        dropzone: {
            uploadFile: 'Загрузите файл',
            delete: 'Удалить',
            dropFile: 'Перенесите файл (или нажмите)',
            formatTooLarge: 'Формат слишком большой',
            invalidType: 'Неподдерживаемый тип файла',
        },
        rangedSelectionDatePicker: {
            placeholder: 'Выберите начальную и конечную даты',
        }
    },
    common: {
        textCopied: 'Текст скопирован',
        yesSure: 'Да, все правильно',
        cancel: 'Отменить',
        create: 'Создать',
        success: "Успешно!",
        save: "Сохранить",
        resume: 'Возобновить',
        date: 'Дата',
        forms: {
            priceCurrency: {
                currency: "Валюта",
                    pickCurrency: "Выберите валюту"
            },
            legalEntity: {
                label: "Юр.лицо для оплаты",
                pickLegalEntity: "Выберите юр. лицо",
            },
            alter_legalEntity: {
                label: "Юр лицо для оплаты аренды",
                pickLegalEntity: "Выберите доп. юр. лицо",
                tooltip: 'Укажите юр лицо, которое будет принимать деньги для тренировок без инструктора / аренде. Это необходимо для соблюдения законодательства.'
            },
            timeZonePicker: {
                label: 'Часовой пояс'
            },
            publicContracts: {
                label: 'Оферта',
                pickContract: 'Выберите контракт'
            },
            alter_publicContracts: {
                label: 'Оферта для аренды',
                pickContract: 'Выберите доп. контракт',
                tooltip: 'Укажите оферту, с которой будет соглашаться пользователь, при покупке занятия без инструктора / аренде. Это необходимо для соблюдения законодательства.'
            },
            city: {
                label: 'Город/место старта',
                placeholder: 'Москва'
            },
            country: {
                label: 'Страна',
                placeholder: 'Россия',
                pickCurrency: "Выберите валюту",
                pickCountry: "Выберите страну"
            },
            movePaymentModalGroups: {
                areYouSureClient: 'Вы уверены, что хотите перенести клиента?',
                areYouSureOrder: 'Вы уверены, что хотите перенести весь заказ?',
                areYouSurePastClient: 'Вы пытаетесь перенести клиента в прошедшее событие, все равно перенести?',
                areYouSurePastOrder: 'Вы пытаетесь перенести заказ в прошедшее событие, все равно перенести?',
                areYouSureClosedClient: 'Вы пытаетесь перенести клиента в закрытое событие, все равно перенести?',
                areYouSureClosedOrder: 'Вы пытаетесь перенести заказ в закрытое событие, все равно перенести?',
                moving: 'Перенос',
                pickDates: 'Выберите даты',
                yes: 'Да, перенести',
                clientMoving: 'Перенос клиента',
                name: 'Название',
                startDate: 'Дата начала',
                endDate: 'Дата конца',
                bookedToTotal: 'Куплено мест',
                pick: 'Выбрать',
            },
            phone: {
                label: 'Телефон',
                minMessage: 'Проверьте длину телефона',
            },
            hideUntil: {
                label: 'Время закрытия'
            },
            countryList: {
                label: 'Страна'
            },
            movePaymentModal: {
                successUpdate: 'Обновление успешно!',
                yes: 'Да, перенести',
                areYouSureClient: 'Вы уверены, что хотите перенести клиента?',
                areYouSureOrder: 'Вы уверены, что хотите перенести весь заказ?',
                areYouSurePastClient: 'Вы пытаетесь перенести клиента в прошедшее событие, все равно перенести?',
                areYouSurePastOrder: 'Вы пытаетесь перенести заказ в прошедшее событие, все равно перенести?',
                areYouSureClosedClient: 'Вы пытаетесь перенести клиента в закрытое событие, все равно перенести?',
                areYouSureClosedOrder: 'Вы пытаетесь перенести заказ в закрытое событие, все равно перенести?',
                clientMoving:'Перенос клиента',
                move: 'Перенос',
                pickBase: 'Выберите базу',
                pickEvent: 'Выберите событие',
                pickDates: 'Выберите даты',
                name: 'Название',
                date: 'Дата',
                time: 'Время',
                reservedSits: 'Куплено мест',
                bookedToTotal: 'Забронировано мест',
                pick: 'Выбрать',
                startDate: 'Дата начала',
                endDate: 'Дата конца',
                pickTravel: 'Выберите приключение',
                paymentMade: 'Оплаченные заказы',
            },
            maxLength: 'Можно ввести до {{count}} знаков'
        },
        extraMaterialsModal: {
            pickExtraMaterials: 'Выберите доп. материал',
            close: 'Закрыть',
        },
        extraMaterialsTable: {
            name: 'Название',
            link: 'Ссылка',
            delete: 'Удалить',
        },
        productOrderList: {
            yes: 'Да, аннулировать',
            areYouSure: 'Вы уверены, что хотите аннулировать весь заказ?',
            deleteTitle: 'Аннуляция полного заказа',
            refundSubscription: 'Cредства вернутся на баланс абонемента.',
            refundCertificate: 'Cредства вернутся на баланс сертификата.',
            purchases: 'Покупки',
            movedAndCancelledPurchases: 'Отмененные и перемещенные покупки',
            moved: 'Перенесен',
            smallMoved: 'перенесен',
            payment: 'Оплата',
            discount: 'скидка',
            common: 'общая',
            individual: 'индивидуальная',
            bankId: 'ID Клиента Тинькофф',
            paidLocally: 'Оплата на месте',
            promocode: 'Промокод',
            paymentTime: 'Время оплаты',
            name: 'Имя',
            lastname: 'Фамилия',
            phone: 'Телефон',
            count: 'Кол-во',
            comment: 'Комментарий',
            sailingExperience: 'Яхтенный опыт',
            email: 'Почта',
            withoutInstructor: 'Без инструктора',
            anyInstructor: 'Любой классный',
            experience: 'Опыт',
            instructor: 'Инструктор',
            copySecondInvoiceLink: 'Скопировать ссылку на второй платеж',
            yourSecondInvoiceLink: 'Ваша ссылка на оплату второго платежа',
            isAdded: 'Записан',
            level: {
              beginner: 'Начинающий',
              experienced: 'С опытом',
              advanced: 'Продвинутый',
            },
            mail_sent: 'Письмо отправлено',
            mail_sent_i_1: '1. Если у всех пользователей стоит красный крестик - проверьте есть ли шаблон письма в кэррот',
            mail_sent_i_2: '2. Если у пользователя стоит зеленая галочка - письмо было отправлено клиенту',
            mail_sent_i_3: '3. Если у пользователя в течении суток с момента оформления заказа стоит крестик - письмо не было отправлено пользователю.',
        },
        popovers: {
            remainder: 'Остаток:',
            writeOffAmount: 'Сумма списания:',
            subscriptionNameCopied: 'Название абонемента скопировано',
            installmentNameCopied: 'Код рассрочки скопирован',
            installmentDuration: 'Продолжительность (мес.)',
            installmentPromocode: 'Код рассрочки',
        },
        timeZone: {
            pickZone: 'Выберите часовой',
            kaliningrad: '+2 UTC, Калининград',
            moscow: '+3 UTC, Москва',
            samara: '+4 UTC, Самара',
            ekaterinburg: '+5 UTC, Екатеринбург',
            omsk: '+6 UTC, Омск',
            krasnoyarsk: '+7 UTC, Красноярск',
            irkutsk: '+8 UTC, Иркутск',
            yakutsk: '+9 UTC, Якутск',
            vladivostok: '+10 UTC, Владивосток',
            magadan: '+11 UTC, Магадан',
            kamchatka: '+12 UTC, Камчатка',
            dubai: '+4 UTC, Дубай',
        },
        totalSitsDecrease: 'При уменьшении кол-ва мест, будет происходить перерасчет загрузки',
        firstName: 'Имя',
        lastName: 'Фамилия',
        email: 'Почта',
        areYouSure: 'Вы действительно хотите удалить это?',
        deleting: 'Удаление',
        comment: 'Комментарий',
        edit: 'Редактирование',
        showAll: 'Показать все',
        hide: 'Скрыть',
        fullName: 'Полное имя',
        add: 'Добавить',
        title: 'Название',
        creating: 'Создание',
        editing: 'Редактирование',
        confirm: 'Подтвердить',
        deleteAnswer: 'Действительно удалить?',
        sport: 'Спорт',
        adventures: 'Приключения',
        school: 'Школа',
        charter: 'Чартер',
        management: 'Управление',
        description: 'Описание',
    },
    inputs: {
        sailingExperience: {
          '0/7': 'нет опыта',
          '1/7': 'знаю базовое устройство яхты',
          '2/7': 'умею настраивать паруса',
          '3/7': 'хожу всеми курсами и могу пришвартоваться',
          '4/7': 'совершенствую технику в деталях',
          '5/7': 'предвижу ветер и ситуации на воде',
          '6/7': 'готовлюсь к победе в гонках',
          '7/7': 'парусный мастер, изучаю стратегию и тактику',
        },
        validation: {
            required: "Поле обязательно",
            onlyNumbers: "Допустимы: цифры",
            minNum: "Число меньше чем {{count}}"
        },
        nominal: {
            label: "Номинал"
        },
        active: {
            label: "Активен"
        },
        saleAvailable: {
            label: "Доступен для продажи"
        },
        required: 'Обязательно',
        optional: 'Необязательно',
        hidden: 'Скрыто',
        hide: 'Скрыть',
        email: {
          label: 'Электронная почта',
          pattern: 'Введите существующий адрес email'
        },
    },
    routes: {
        bases: "Базы",
        adventures: "Приключения",
        russia: "По России",
        world: "По всему миру",
        school: "Школа",
        theory: "Теория",
        practices: "Практика",
        lectures: "Лекторий",
        kids_school: "Школа Дети",
        administration: "Управление",
        orders: "Покупки",
        instructors: "Инструкторы",
        captains: "Капитаны",
        promocodes: "Промокоды",
        certificates: "Сертификаты",
        violations: "Блэклист",
        feedback: "Отзывы",
        promocodes_packages: "Пакеты Промокодов",
        exports: "Экспорт",
        users: "Пользователи",
        links_generator: "Генератор Ссылок",
        directories: "Справочники",
        subscriptions: "Абонементы",
        difficulties: "Шкалы сложности",
        user_fields: "Пользовательские поля",
        user_fields_templates: "Шаблоны польз. полей",
        currencies: "Валюты",
        boats_classes: "Классы лодок",
        shirt_sizes: "Размеры футболок",
        coordinators: "Кураторы",
        base_addresses: 'Адреса баз',
        public_contracts: "Оферты",
        extra_materials: "Доп. Материалы",
        legal_entities: "Юр. Лица",
        checks: "Чеки",
        constructor: "Корп. отдел",
        projects: "Проекты",
        templates: "Шаблоны",
        pages: "Страницы",
        letters: "Письма",
        files: "Файлы",
        edit: 'Редактирование',
        create: 'Создание',
        corp_clients: 'Ссылки для корп',
        transaction_status: 'Статусы транзакций',
        camps: 'Лагеря',
        materials: 'Материалы',
        frontendRoles: 'Пользовательские роли',
        charters: 'Чартер',
        constructorDirectory: 'Справочник данных',
        baseAddresses: 'Адреса баз',
        corp_users: 'Корп. клиенты',
        areas: 'Площадки',
        global_settings: 'Глобальные настройки',
        separate_functionality: 'Отдельный функционал',
        formats: 'Форматы'
    },
    management: {
        breadcrumbsAndTitle: {
            management: "Управление",
            blacklist: "Блэклист",
            create: "Создание",
            view: "Просмотр",
            review: "Отзывы",
            edit: "Редактирование",
            purchases: "Покупки",
            captains: "Капитаны",
            users: "Пользователи",
            instructors: "Инструкторы",
            editInstructor: "Редактирование инструктора",
            promoCodePackages: "Пакеты промокодов",
            promoCodes: "Промокоды",
            certificates: "Сертификаты",
            export: "Экспорт",
            linksGenerator: "Генератор ссылок",
            materials: 'Материалы',
            frontendRoles: 'Пользовательские роли'
        },
        certificates: {
            list: {
                all: "Все",
                active: "Активные",
                inactive: "Неактивные",
                isActive: "Активен",
                create: "Создать",
                modalTitle: "Архивировать сертификат",
                deleteText: "Вы уверены?",
                confirmButtonText: "Да",
                titleInputLabel: "Название",
                ownerInputLabel: "Держатель",
                ownerInputPlaceholder: "Имя, фамилия, email, телефон",
                senderInputLabel: "Отправитель",
                productTypeInputLabel: "Тип продукта",
                stateInputLabel: "Состояние",
                manager: "Менеджер",
                ownerName: "ФИ держателя",
                title: "Название",
                product: "Продукт",
                remains: "Остаток",
                sum: "Сумма",
                ownerPhone: "Телефон держателя",
                ownerMail: "Почта держателя",
                senderMail: "Почта отправителя",
                ownerInputPhoneHint: 'Чтобы найти номер телефона клиента – введите номер в любом формате либо часть номера без кода страны (без 8 / +7).',
                ownerInputNameHint: 'Также поиск можно осуществить по Фамилии и Имени.'
            },
            changeModal: {
                header: "Изменение сертификата",
                sizeInputLabel: "Сумма сертификата",
                sizeLeftInputLabel: "Остаток сертификата",
                cancel: "Отмена",
                ok: "Ок"
            },
            certificateStatus: {
                size: "Размер",
                sizeLeft: "Остаток",
                changeState: "Изменить состояние"
            },
            queueStatus: {
                status: "Статус в очереди",
                notDefined: "не определен",
                dateOfLastAction: "Дата последнего действия",
                lastRecipientEmail: "Почта последнего получателя",
                error: "Ошибка (если была)"
            },
            tabsGeneralIndex: {
                certificateParameters: "Параметры сертификата",
                paymentParameters: "Платежные параметры",
                copyCode: "Копировать код",
                purchasePage: "Страница покупки"
            },
            tabsGeneralParameters: {
                russia: "Россия",
                belarus: "Беларусь",
                uae: "ОАЭ",
                requiredField: "Поле обязательно",
                recipientMail: "Почта получателя",
                recipientName: "Имя получателя",
                recipientSurname: "Фамилия получателя",
                recipientPhone: "Телефон получателя",
                recipientNamePlaceholder: "Иван",
                recipientSurnamePlaceholder: "Иванов",
                productType: "Тип продукта",
                certificateType: "Тип сертификата",
                activityDate: "Дата активности",
                countryOfApplication: "Страна применения",
                nominalValue: "Номинал",
                buyerMail: "Почта покупателя",
                realMail: "Можно настоящую",
                comment: "Комментарий клиента",
                commentHint: "45 знаков - предел",
                commentPlaceholder: "Хорошего ветра!",
                commentMaxLength: "Больше {{count}} симв.",
                managerComment: "Комментарий менеджера",
                managerCommentPlaceholder: "Комментарий менеджера...",
                minError: 'Значение должно быть больше 0',
            },
            tabsPaymentInfo: {
                statusChanged: "Статус платежа изменен",
                certificateAddedToQueue: "Отправка сертификата успешно добавлена в очередь!",
                invoiceHasBeenGenerated: "Инвойс сгенерирован!",
                invoiceAddedToQueue: "Отправка инвойса успешно добавлена в очередь!",
                cancelPayment: "Аннулировать оплату",
                toPay: "Оплатить",
                generateAnInvoice: "Сформировать инвойс",
                orderHasAlreadyBeenPaid: "Заказ уже был оплачен",
                paymentLink: "ссылка на оплату",
                validUntil: "срок действия до",
                send: "Выслать",
                sendToRecipient: "Отправить получателю",
                sendToBuyer: "Отправить покупателю",
                downloadFile: "Скачать файл",
                hasPaid: "Оплачено",
                sendCertificateToRecipient: "Отправить сертификат получателю"
            },
            tabList: {
                generalData: "Общие данные",
                historyOfChanges: "История изменений",
                queueHistory: "История очереди"
            },
            history: {
                purchase: "Покупка",
                manual: "Ручное",
                operatorDownload: "Менеджер скачал файл",
                typeChange: "Сменен тип",
                change: "Изменение",
                noChange: "Без изменения",
                changeReason: "Причина",
                whoApplied: "Кто применил",
                product: "Продукт",
                time: "Время",
                comment: "Комментарий",
                add: "Добавить",
                commentPlaceholder: "Комментарий менеджера...",
                update: "Обновлено",
                create: "Создано",
                changes: {
                  assignee_comment: "Комментарий получателя",
                  assignee_email: "Почта получателя",
                  assignee_full_name_search: "Полное имя получателя",
                  assignee_name: "Имя получателя",
                  assignee_phone: "Телефон получателя",
                  assignee_surname: "Фамилия получателя",
                  available_from: "Доступно с",
                  available_to: "Доступно по",
                  country: "Страна",
                  is_active: "Активен",
                  is_created_manually: "Создан вручную",
                  name: "Название",
                  price_currency: "Валюта",
                  product_type: "Тип продукта",
                  queue_action_date: "Дата очереди",
                  queue_action_email: "Почта очереди",
                  queue_error_message: "Ошибка очереди",
                  queue_status: "Статус очереди",
                  send_if_paid: "Отправить если оплачен",
                  senders_email: "Почта отправителя",
                  size: "Размер",
                  size_left: "Остаток",
                  type: "Тип",
                }
            },
            certificateIndex: {
                success: "Успешно!",
                editing: "Редактирование",
                creating: "Создание",
                certificate: "сертификата",
                save: "Сохранить",
                cancel: "Отменить"
            },
            universal: 'Универсальный'
        },
        export: {
            title: "Все файлы экспорта",
            entity: "Сущность",
            progress: "Прогресс",
            status: "Статус",
            file: "Файл",
            user: "Пользователь",
            description: "Описание",
            date: "Дата",
            statusReady: "готово",
            statusProcessing: "в процессе",
            statusError: "ошибка",
            lines: "строк"
        },
        feedback: {
            titleReview: "Просмотр отзыва",
            added: "Оставлен",
            product: "Продукт",
            mail: "Почта",
            phone: "Телефон",
            evaluation: "Оценка",
            evaluationFrom: "Оценка \"от\"",
            evaluationTo: "Оценка \"до\"",
            name: "Имя",
            surname: "Фамилия",
            firstLastName: "ФИ",
            comment: "Комментарий",
            backButton: "Назад",
            chooseProduct: "Выберите продукт",
            productType: "Тип продукта",
            date: "Дата",
        },
        instructors: {
            editModal: {
                instructor: "Инструктор",
                createNewInstructor: "Создать нового инструктора",
                instructorData: "Данные инструктора",
                chooseFromExisting: "Выбрать из существующих",
                client: "Клиент",
                clientSelected: "Клиент выбран",
                goToClientRecord: "Перейти в запись клиента",
                success: "Успех",
                createInstructor: "Создание инструктора",
                ok: "Ок",
                cancel: "Отмена",
                requiredField: "Поле обязательно",
                patronymic: "Отчество",
                patronymicPlaceholder: "Иванович",
                instructorMail: "Почта инструктора",
                fileHint: "Размер файла не более 3 мб",
                avatar: "Аватар",
                chooseBoatClasses: "Выберите классы лодок",
                boatClass: "Классы лодок",
                wellDone: "Молодец!",
                comment: "Комментарий",
                chooseBase: "Выберите базу",
                attachToBase: "Прикреплен к базе",
                status: "Статус",
                chooseStatus: "Выберите статус",
                active: "Активный",
                inactive: "Неактивный",
                ill: "Болен",
                name: "Имя",
                surname: "Фамилия",
                surnamePlaceholder: "Иванов",
                namePlaceholder: "Иван",
            },
            instructor: {
                instructor: "Инструктор",
                instructorData: "Данные инструктора",
                schedule: "Расписание",
                success: "Успех",
                ok: "Ок",
                goToClientRecord: "Перейти в запись клиента",
                date: "Дата",
                time: "Время",
                boat: "Лодка",
                whoBooked: "Кто забронировал",
                slot: "Слот"
            },
            instructorList: {
                instructor: "Инструктор",
                active: "Активный",
                inactive: "Неактивный",
                ill: "Болен",
                allBases: "Все базы",
                allYachtClasses: "Все классы яхт",
                searchByInstructors: "Поиск по инструкторам",
                status: "Статус",
                comment: "Комментарий",
                yachtClasses: "Классы яхт",
                attachedToBase: "Прикреплен к базе"
            }
        },
        linksGenerator: {
            events: "События",
            linksHistory: "История ссылок",
            link: "Ссылка",
            sport: "Спорт",
            theory: "Теория",
            enterField: "Введите одно из полей",
            base: "База",
            choose: "Выберите...",
            eventType: "Тип события",
            boardType: "Тип лодки",
            followLink: "Перейти по ссылке...",
            generate: "Сгенерировать",
            activityType: "Тип занятия",
            notAvailableOnline: "Недоступно онлайн",
            city: "Город",
            eventFormat: "Формат проведения",
            online: "Онлайн",
            regular: "Обычный",
            offline: "В офисе",
            intensive: "Интенсив",
            product: {
                title: "Продукт",
                placeholder: "Выберите..."
            }
        },
        orders: {
            orderTable: {
                transferToAnotherProduct: "Перенести в другой продукт",
                goToUserProfile: "Перейти в профиль пользователя",
            },
            deleteButton: {
                delete: "Удалить",
                confirmButtonText: "Да, удалить",
                confirmText: "Вы уверены, что хотите удалить человека из заказа?",
                confirmTitle: "Аннуляция части заказа",
                deleteFromOrder: "Удалить из заказа"
            },
            customFormControls: {
                instructor: "Инструктор",
                anyoneCool: "Любой классный",
                places: "мест",
            },
            orderList: {
                noPayment: "Нет оплаты",
                rbk: "РБК",
                fondy: "Фонди",
                tinkoff: "Тинькофф",
                cloudPayments: "Клаудпейментс",
                alpha: "Альфа",
                promoCode: "Промокод",
                free: "Бесплатно",
                subscription: "Абонемент",
                certificate: "Сертификат",
                manual: "Ручной",
                paidLocally: "Оплата на месте",
                paidFor: "Оплачено",
                notPaid: "Не оплачено",
                promoCodeCopied: "Название промокода скопировано",
                subscriptionCopied: "Название абонемента скопировано",
                discountGeneral: "скидка: общая",
                individual: "индивидуальная",
                probablyFraudster: "Возможно мошенник",
                soloDiscount: "Скидка за бронь без инструктора",
                fullBoardDiscount: "Скидка за бронь полной лодки",
                any: "Любая",
                card: "Картой",
                byPromoCode: "Промокодом",
                bySubscription: "Абонементом",
                create: "Создать",
                name: "Имя",
                mail: "Почта",
                queue: "В очереди",
                yes: "Да",
                no: "Нет",
                currency: "Валюта",
                paymentType: "Время оплаты",
                productType: "Тип продукта",
                product: "Продукт",
                slot: "Слот",
                arrival: "Заезд",
                theory: "Теория",
                lecture: "Лекторий",
                stripe: 'Stripe',
                transactionStatus: 'Статус',
                byCash: 'Наличными',
            },
            order: {
                userComment: "Комментарий пользователя",
                discountInfo: "Информация о скидках",
                promoCode: "Промокод",
                promoCodeCopied: "Название промокода скопировано",
                certificate: "Сертификат",
                certificateCopied: "Название сертификата скопировано",
                subscription: "Абонемент",
                subscriptionCopied: "Название абонемента скопировано",
                paymentTime: "Время оплаты",
                productUrl: "URL продукта",
                productType: "Тип продукта",
                slots: "Слоты",
                arrivals: "Заезды",
                groups: "Группы",
                subscriptions: "Абонементы",
                currency: "Валюта",
                price: "Цена",
                entered: "Внесено",
                percent: "Процент",
                paid: "Оплачено",
                inQueue: "В очереди",
                pay: "Оплатить",
                cancelPayment: "Аннулировать платеж",
                paidFor: "Оплачено за",
                addCustomersToPayment: "Добавить клиентов к платежу",
                assignPayer: "Назначить плательщика",
                save: "Сохранить",
                paymentStatusChanged: "Статус платежа изменен",
                close: "Закрыть",
                selectClient: "Выберите клиента",
                payInFull: "Оплатить полностью",
                money: "Деньги"
            },
            statistic: {
              slots: 'Тренировки',
              arrivals: 'Приключения и Практики',
              groups: 'Теория и Лекторий',
              count: 'Кол-во покупок',
              canceledRefunded: 'Кол-во Отмененных/Возвращенных',
              moved: 'Кол-во перенесенных',
            },
            passportsModal: {
              passExists: 'Паспорт',
              title: 'Паспорта'
            }
        },
        promoCodePackages: {
            create: "Создать",
            success: "Успешно!",
            packageSetup: "Настройка пакета",
            packageName: "Название пакета",
            prefix: "Префикс",
            requiredField: "Поле обязательно",
            packageSize: "Размер пакета",
            setupPromoCode: "Настройка промокода",
            searchPromoCodes: "Найти промокоды",
            confirmDelete: "Да, удаляем",
            deleteAnswer: "Вы уверены, что хотите удалить пакет?",
            deleteTitle: "Удаление пакет",
            creator: "Создатель"
        },
        promoCodes: {
            showArchive: "Показать архив",
            useForAbonements: "Применение промокода к абонементам",
            create: "Создание",
            edit: "Редактирование",
            ofPromoCode: "промокода",
            promoCodeName: "Название промокода",
            startDate: "Дата начала",
            endDate: "Дата конца",
            indefinite: "бессрочный",
            usingMax: "Использований макс.",
            totalUsages: "Использований всего",
            repaid: "Погашен",
            archived: "Архивирован",
            allProducts: "Все продукты",
            adventures: "Приключения",
            adventuresRus: "Приключения: по России",
            adventuresWorld: "Приключения: по всему миру",
            allBases: "Все Базы ( + события и слоты )",
            events: "События: ",
            eventsHike: "События: Поход",
            captainsSchool: "Школа капитанов",
            courseTheory: "Курс: теория",
            coursePractice: "Курс: практика",
            captainsLecture: "Курс: лекторий",
            comment: "Комментарий",
            commentDesc: "В поле комментария пиши для чего создается промокод",
            typeOfNameGeneration: "Тип генерации имен",
            manual: "Ручная",
            combined: "Полуавтоматическая",
            automatic: "Автоматическая",
            basesForAttachment: "Базы для прикрепления",
            chooseBases: "Выберите базы",
            chooseConditions: "Выберите условия",
            andOr: "И (ИЛИ)",
            chooseProduct: "Выберите продукт",
            whatIsAppliedTo: "К чему применяется",
            phone: "Телефон",
            currency: "Валюта",
            requiredField: "Поле обязательно",
            sum: "Сумма",
            percent: "Процент",
            type: "Тип",
            enoOfPromoCode: "Конец действия промокода",
            openEnd: "Открытый конец",
            startOfPromoCode: "Начало действия промокода",
            title: "Название",
            success: "Успешно!",
            ofAdventures: "приключений",
            ofArrivals: "заездов",
            ofEvents: "событий",
            ofGroups: "групп",
            ofSlots: "слотов",
            promoCodeProductTypeDescription: "Этот промокод применяется для отдельного продукта",
            usages: 'исп.',
            usageType: 'Тип использования',
            normal: 'Обычный',
            welcome: 'Welcome',
            disposable: 'Одноразовый',
            creator: 'Создатель',
            camps: 'Все лагеря',
            campsRus: 'Лагеря: по России',
            campsWorld: 'Лагеря: по миру',
            specialConditions: 'Oсобые условия и механики',
            specialConditionsInfo: 'Промокод можно применить только при использовании авторизации Tinkoff',
            subscriptions: 'Абонементы',
        },
        users: {
            chooseRole: "Выберите роль",
            surnamePlaceholder: "Иванов",
            namePlaceholder: "Иван",
            patronymicPlaceholder: "Иванович",
            create: "Создать",
            editing: "Редактирование",
            creating: "Создание",
            user: "пользователя",
            name: "Имя",
            surname: "Фамилия",
            nameAndSurname: "Имя и Фамилия",
            patronymic: "Отчество",
            mail: "Почта",
            phone: "Телефон",
            blocked: "Блокирован",
            role: "Роль",
            yes: "Да",
            no: "Нет",
            registered: "Зарегистрирован",
            insured: "Застрахован",
            nameOrSurname: "Имя / Фамилия",
            data: "Данные",
            technicalData: "Технические данные",
            purchaseHistory: "История покупок",
            subscription: "Абонемент",
            eventsHistory: "История событий",
            changeHistory: "История изменений",
            cancel: "Отменить",
            save: "Сохранить",
            phoneAlreadyExist: "Такой телефон уже используется!",
            success: "Успешно!",
            subscriptionChangeTitle: "Изменение абонемента",
            subscriptionSum: "Сумма абонемента",
            subscriptionIntValidation: "Должны быть только числа",
            notSelected: "Не выбран",
            comment: "Комментарий",
            ok: "Ок",
            purchaseSemicolon: "Покупка; ",
            rechargeSemicolon: "Пополнение; ",
            manualSemicolon: "Ручное изменение; ",
            size: "Размер",
            totalDiscount: "Скидка (общая)",
            individualDiscount: "Скидка (индив.)",
            sailGPDiscount: "Скидка (гран-при.)",
            racingDiscount: "Скидка (гонки)",
            change: "Изменить",
            changeMagnitude: "Величина изменения",
            changeReason: "Причина изменения",
            operator: "Оператор",
            product: "Продукт",
            time: "Время",
            booking: "Бронирование",
            paymentApproved: "Оплата одобрена",
            refund: "Возврат",
            paymentDeclined: "Оплата отклонена",
            postpone: "Перенос времени",
            moved: "Перенос покупки",
            removedFromPayment: "Удален из оплаты",
            action: "Действие",
            reason: "Причина",
            purchase: "покупка",
            requiredField: "Поле обязательно",
            shirtSize: "Размер футболки",
            nameEng: "Имя на английском",
            surnameEng: "Фамилия на английском",
            birthDate: "Дата рождения",
            passportNumRu: "Паспорт РФ - Серия/номер",
            passportDateRu: "Паспорт РФ - Дата выдачи",
            passportAuthoritiesRu: "Паспорт РФ - Кем выдан",
            passportNumForeign: "Заг. Паспорт - Серия/номер",
            passportDateForeign: "Заг. Паспорт - Действителен до",
            citizenship: "Гражданство",
            gender: "Пол",
            male: "м",
            female: "ж",
            sailingCardId: "ID яхтенной карты",
            experience: "Опытность",
            characteristic: "Характеристика",
            country: "Страна",
            countryPlaceholder: "Россия",
            city: "Город",
            cityPlaceholder: "Москва",
            additionalInfo: "Дополнительная информация",
            additionalInfoPlaceholder: "Пушкина, д.12",
            password: "Пароль",
            minLengthMessage: "Минимальная длинна 5 символов",
            authorizationService: "Сервис авторизации",
            hasLoyalDiscount: "Скидка 50 евро",
            hasBlocked: "Заблокирован",
            confirmedMail: "Подтвердил почту",
            serviceRecord: "Сервисная запись",
            sendLetters: "Отправлять письма",
            onlyMasterOrders: "Только мастер заказы",
            unsubscribed_news: "Отписаться от новостей",
            is_corp: "Корпоративный клиент",
            sailingExperience: {
              '0/7': 'нет опыта',
              '1/7': 'знаю базовое устройство яхты',
              '2/7': 'умею настраивать паруса',
              '3/7': 'хожу всеми курсами и могу пришвартоваться',
              '4/7': 'совершенствую технику в деталях',
              '5/7': 'предвижу ветер и ситуации на воде',
              '6/7': 'готовлюсь к победе в гонках',
              '7/7': 'парусный мастер, изучаю стратегию и тактику',
            },
            frontendRole: 'Пользовательская роль',
            insuranceId: 'Код страховки',
            insurance: 'Страхование',
            insuranceHistory: 'История страховок'
        },
        violations: {
            blacklist: "Блэклист",
            selectedUser: "Выбранный пользователь",
            added: "Оставлен",
            createButton: "Создать",
            backButton: "Назад",
            description: "Описание",
            name: "Имя",
            namePlaceholder: "Иван",
            surname: "Фамилия",
            surnamePlaceholder: "Иванов",
            patronymic: "Отчество",
            patronymicPlaceholder: "Иванович",
            mail: "Почта",
            product: "Продукт",
            notSelected: "не выбран",
            chooseProduct: "Выберите продукт",
            productType: "Тип продукта",
            violation: "Нарушение",
            date: "Дата",
            manager: "Менеджер"
        },
        frontRoles: {
            roles: 'Какие роли может назначать',
            rolesInfo: 'Роль которую вы создаете или редактируете, сможет выдавать роли указанные ниже',
            rules: 'Пользовательские правила',
            item: 'Название новой роли',
        }
    },
    frontRoles: {
        breadcrumbs: {
            bases: 'Базы',
            group: 'Хлебные крошки',
        },
        certificates: {
            bases: 'Базы',
            group: 'Cертификаты',
            item: {
                edit: 'Изменение и создание',
                tab: {
                    history: 'Просмотр (история изменений)',
                }
            }
        },
        promocodes: {
            group: 'Промокоды',
            item: {
                edit: 'Изменение и создание'
            }
        },
        bases: {
            list: {
                row: {
                    gear: 'Редактирование базы (шестерня)'
                }
            },
            item: {
                instructors: 'Инструкторы'
            },
            group: 'Базы',
        },
        base: {
            events: {
                export: 'Экспорт событий',
                creation: 'Создание события',
            },
            slots: {
                list: {
                    row: {
                        hide: 'Скрыть слот',
                        boats: 'Лодки слота',
                        close: 'Закрыть слот',
                        remove: 'Удалить слот',
                        instructors: 'Инструкторы слота',
                    }
                }
            },
            group: 'База',
        },
        event: {
            item: {
                edit: 'Редактировать событие',
                export: 'Экспортировать событие',
                slots: {
                    creation: 'Создать слот',
                    list: {
                        row: {
                            remove: 'Удалить слот'
                        }
                    }
                }
            },
            group: 'Событие',
        },
        slot: {
            item: {
                boats: 'Лодки',
                tabs: {
                    general: 'Таб общее',
                    promos: 'Таб промокоды',
                    instructors: 'Таб инструкторы',
                },
                orders: {
                    creation: 'Создание заказа',
                    list: {
                        row: {
                            edit: 'Редактирование заказа',
                            remove: 'Удаление заказа',
                            transfer: 'Перенос заказа',
                        }
                    }
                }
            },
            group: 'Слот'
        },
        sidebar: {
            bases: 'Базы',
            charter: 'Чартер',
            school: 'Школа',
            adventures: 'Приключения',
            constructor: 'Корп. отдел',
            camps: {
                world: 'Лагеря мир',
                russia: 'Лагеря россия',
            },
            management: {
                users: 'Пользователи',
                orders: 'Покупки',
                promos: 'Промокоды',
                reviews: 'Отзывы',
                exports: 'Экспорт',
                blacklist: 'Черный список',
                materials: 'Материалы',
                instructors: 'Инструкторы',
                certificates: 'Сертификаты',
                promopackages: 'Пакеты промокодов',
                linksgenerator: 'Генератор ссылок'
            },
            directory: {
                offers: 'Оферты',
                merchants: 'Юр. лица',
                corpclients: 'Корп. клиенты',
                transstatus: 'Статус транзакций',
                boatclasses: 'Классы лодок',
                tshortsizes: 'Размеры футболок',
                customfields: 'Пользовательские поля',
                subscriptions: 'Абонементы',
                extramaterials: 'Доп. материалы',
                difficultyscales: 'Шкалы сложностей',
                templatescustomfields: 'Шаблоны пользовательских полей',
                checks: 'Чеки'
            },
            group: 'Боковая панель'
        },
        practise: {
            list: {
                item: {
                    remove: 'Удаление практики',
                }
            },
            item: {
                arrivals: {
                    list: {
                        row: {
                            remove: 'Удаление заезда',
                        }
                    }
                }
            },
            group: 'Практика'
        },
        adventures: {
            list: {
                item: {
                    remove: 'Удаление приключения',
                }
            },
            item: {
                arrivals: {
                    list: {
                        row: {
                            remove: 'Удаление заезда',
                        }
                    }
                }
            },
            group: 'Приключения'
        },
        charter: {
            list: {
                item: {
                    remove: 'Удаление чартового приключения',
                }
            },
            item: {
                arrivals: {
                    list: {
                        row: {
                            remove: 'Удаление чартового заезда',
                        }
                    }
                }
            },
            group: 'Чартер'
        },
        theory: {
            list: {
                row: {
                    remove: 'Удаление теории'
                }
            },
            group: 'Теория'
        },
        lecture: {
            list: {
                row: {
                    remove: 'Удаление лектория'
                }
            },
            group: 'Лекторий'
        }
    },
    utils: {
        monday: 'ПН',
        tuesday: 'ВТ',
        wednesday: 'СР',
        thursday: 'ЧТ',
        friday: 'ПТ',
        saturday: 'СБ',
        sunday: 'ВС',
    },
    layout: {
        login: 'Логин',
        password: 'Пароль',
        enter: 'Войти',
        exit: 'Выйти',
    },
    lib: {
        duplicateEntry: 'Промокод с таким названием уже существует',
        validationError: 'Ошибка валидации данных',
        methodNotAllowed: 'Что-то пошло не так :(',
        notFound: 'Не найдено',
    },
    constructor: {
        label: 'Название',
        key: 'Ключ',
        keyPlaceholder: 'template_key',
        public: 'Публичный',
        createdAt: 'Создано',
        updatedAt: 'Обновлено',
        projects: {
            createProject: 'Создать проект',
            deleting: 'Удаление проекта',
            areYouSure: 'Вы точно хотите удалить проект?',
            newProject: 'Новый проект',
            editModalChildren: {
                labelPlaceholder: 'Сбербанк'
            }
        },
        extraMaterials: {
            link: "Ссылка",
            name: "Название",
            namePlaceholder: "Как выбрать название?",
        },
        directoryTemplate: {
            create: "Создать",
            search: {
                coordinator: 'Поиск по куратору',
                base_addresses: 'Поиск адреса базы',
                areas: 'Поиск по площадкам',
                formats: 'Поиск по форматам',
            }
        },
        templates: {
            labelPlaceholder: 'Название шаблона',
            create: 'Создать шаблон',
            deleting: 'Удаление шаблона',
            areYouSure: 'Вы точно хотите удалить шаблон?',
            new: 'Новый шаблон',
            logo: 'Логотип',
            start_date: 'Дата старта',
            start_time: 'Время старта',
            end_time: 'Время окончания',
            instructor_name: 'Имя инструктора',
            rules_link: 'Ссылка на правила',
            commercial_offer: 'Коммерческое предложение',
            presentaion: 'Презентация',
            formats: 'Форматы',
            photos_link: 'Ссылка на фото',
            getting_there_link: 'Как добраться ссылка',
            coordinator_name: 'ФИ куратора',
            coordinator_phone: 'Корпоративный номер телефона куратора',
            coordinator_corp_phone: 'Личный номер телефона куратора',
            coordinator_photo: 'Фото куратора',
            more_link: 'Больше о тренировках',
            duration: 'Длительность',
            card_template: 'Приглашение',
            phone: 'Телефон',
            availableParams: 'Доступные параметры:',
            what_bring: 'Что принести с собой',
            promocode: 'Промокод',
            show_photo: 'Показать блок фото',
            show_discounts: 'Показать скидки',
            show_block_information: 'Блок информации',
            ya_maps_widget: 'Виджет карт Яндекс',
            base_addresses: 'Адреса баз',
            event_format: 'Формат',
            description: 'Описание',
            timing: 'Тайминг',
            auth_button_link: 'Ссылка для кнопки авторизации',
            areas: 'Площадки',
        },
        coordinators: {
            name: "Имя",
            phone: "Личный номер телефона",
            corp_phone: "Корпоративный номер телефона",
            type: "Check type",
            namePlaceholder: "Greek vacation",
            chooseType: "Choose type",
            varToUse: "You can use variable:",
            baseVarInfo: " - in the check will be an event base ",
            yachtVarInfo: " - in the check will be a boat ",
            example: "Example: Train on ",
            exampleRes: "In the check will be: Train on ",
        },
        areas: {
            name: "Имя",
            description: "Описание",
            img: "Картинки",
            namePlaceholder: "База Строгино",
            descriptionPlaceholder: "Адрес: База 'Строгино Тест', Москва, Строгинское ш., вл. 1а\nОписание базы: Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla voluptates sed minima doloribus eius nobis earum saepe.",
        },
        formats: {
            name: "Имя",
            description: "Описание",
            img: "Картинки",
            namePlaceholder: "База Строгино",
            descriptionPlaceholder: "Адрес: База 'Строгино Тест', Москва, Строгинское ш., вл. 1а\nОписание базы: Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla voluptates sed minima doloribus eius nobis earum saepe.",
        },
        baseAddresses: {
            name: "Название базы",
            description: "Описание",
            imgUrls: "Картинки",
            city: "Город",
            address: "Адрес",
            building: "Здание",
            getting_there_link: "Ссылка как добраться",
            namePlaceholder: "База 'Строгино'",
            cityPlaceholder: "Москва",
            addressPlaceholder: "Строгинское ш.",
            buildingPlaceholder: "вл. 1а",
            gettingThereLinkPlaceholder: "https://google.com",
            descriptionPlaceholder: "Текст описания",
        },
        corpClients: {
            title: "Название",
            slug: "Короткое имя",
            events: "События"
        },
        pages: {
            labelPlaceholder: 'Название страницы',
            instructorNamePlaceholder: 'Иван',
            rulesLinkPlaceholder: 'https://google.com',
            photosLinkPlaceholder: 'https://google.com',
            moreLinkPlaceholder: 'https://google.com',
            coordinatorNamePlaceholder: 'Иван',
            gettingThereLinkPlaceholder: 'https://google.com',
            promocodePlaceholder: 'silavetra',
            baseAddressPlaceholder: 'Название базы',
            yaMapsWidgetPlaceholder: '<iframe></iframe>',
            eventFormatPlaceholder: `Вас ждут азартные гонки под парусом! Что это значит?`,
            descriptionPlaceholder: 'Вас поделят на команды и каждая будет бороться за первое место, а судья – следить за соблюдением всех правил.',
            durationPlaceholder: 'Длительность — 2 часа, включая начальную получасовую тренировку на воде.',
            timingPlaceholder: `13:00 Сбор гостей\n14:00 Старт регаты\n16:00 Награждение\n16:30 Банкет`,
            project: 'Проект',
            template: 'Шаблон',
            pickProject: 'Выберите проект',
            pickTemplate: 'Выберите шаблон',
            deleting: 'Удаление страницы',
            areYouSure: 'Вы точно хотите удалить страницу?',
            projectKey: 'Ключ проекта',
            projectKeyPlaceholder: 'google',
            cardTemplate: 'Приглашение',
            cardTemplatePlaceholder: 'Приключение в Строгино',
            projectLabel: 'Название проекта',
            projectLabelPlaceholder: 'Google Corp.',
            promocode: 'Промокод',
            coordinator: 'Куратор',
            yaMapsWidget: 'Виджет карт Яндекс',
            base_address: 'Адреса баз',
            areas: 'Площадки',
            event_format: 'Формат',
            description: 'Описание',
            duration: 'Длительность',
            timing: 'Тайминг',
            formats: 'Форматы',
        },
        files: {
            areYouSure: 'Вы точно хотите удалить файл?',
            deleting: 'Удаление файла',
            mime: 'Тип',
            size: 'Размер',
            image: 'Превью',
            upload: 'Загрузить файл',
            file: 'Файл',
        }
    },
    globalSales: {
      form: {
        discountedPrice: "Новая цена",
        startDate: "Дата начала",
        endDate: "Дата конца",
        title: "Название",
        count: "Осталось в продаже",
        startTime: "Время начала",
        endTime: "Время конца",
        range: "Диапазон",
        limit: "Лимит",
        sold: "Продано со скидкой",
        timezone: "Часовой пояс",
      },
      creationAlert: "У вас есть открытая распродажа. Единовременно может быть только одна открытая распродажа."
    },
    managerComments: {
      placeholder: 'Введите коментарий для менеджера...',
    },
}