import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Col, FormGroup, FormText, Label, Row} from "reactstrap";
import {RangedDatePicker, SelectControllerRHF, TextControllerRHF} from "../../../../../../generic/fields";
import {PhoneRHF, PriceCurrencyRHF} from "../../../../../../common/reactHookFormsFields";
import { emailRule, onlyNumberRequired } from '../../../../../../common/reactHookFormsFields/rules';
import dayjs from "dayjs";
import {
    CERTIFICATE_TYPES_LANG_ADAPTER,
    PRODUCT_TYPES,
    PRODUCT_TYPES_LANG_ADAPTER,
} from "sv-common/constants/certificates";
import i18n, {t} from "i18next";
import {COUNTRY} from "../../../../../../../lib/utils/constants";
import {getCountryCode} from "../../../../../../../lib/utils/helper";
import "./style.scss";
import { isRuleAccess } from '../../../../../../../lib/utils/frontRules';
import { frk } from 'sv-common/constants/frontRoles';

const getCountriesOptions = () =>  COUNTRY === 'RU' ? <>
    <option value={'russia'}>{t('management.certificates.tabsGeneralParameters.russia')}</option>
</> : <>
    <option value={'uae'}>{t('management.certificates.tabsGeneralParameters.uae')}</option>
</>

const defaultCountry = COUNTRY === 'RU' ? 'russia' : 'uae'

export const getProductOptions = (language) => ([
    <option value={PRODUCT_TYPES.UNIVERSAL} key={PRODUCT_TYPES.UNIVERSAL}>{PRODUCT_TYPES_LANG_ADAPTER[language][PRODUCT_TYPES.UNIVERSAL]}</option>
])

const getCertificateTypes = (language) => ([
    ...Object.keys(CERTIFICATE_TYPES_LANG_ADAPTER[language])
        .map(type =>
            <option value={type} key={type}>{CERTIFICATE_TYPES_LANG_ADAPTER[language][type]}</option>
        )
])

const Parameters = ({state, dispatch, form}) => {
    const {language} = i18n
    const {control, errors, setValue} = form;
    const [daysFilter, setDaysFilter] = useState({
        from: state.id ? (state.available_from ? dayjs(state.available_from).toDate() : null) : dayjs().toDate(),
        to: state.id ? dayjs(state.available_to).toDate() : dayjs().add(1, 'y').toDate()
    })
    const isEditDisabled = !isRuleAccess(frk["CERTIFICATES-ITEM-EDIT"])();

    useEffect(() => {
        const availableTo = daysFilter.from
            ? dayjs(daysFilter.from).add(1, "y").toDate()
            : daysFilter.to
                ? dayjs(daysFilter.to).toDate()
                : '';
        dispatch({payload: {available_from: daysFilter.from, available_to: availableTo}, type: 'setForm'})
        setDaysFilter(prevState => ({...prevState, to: availableTo}))
    }, [daysFilter.from])

    const setFormValue = e => dispatch({payload: e, type: 'setFormData'})

    return (
        <Row className={'align-items-start certificateParameters'}>
            <Col xs={12} md={6}>
                <FormGroup>
                    <TextControllerRHF inputName={'assignee_email'} control={control} setValue={setFormValue} disabled={isEditDisabled}
                                       placeholder={'username@email.com'} errors={errors} label={t('management.certificates.tabsGeneralParameters.recipientMail')}
                                       defaultValue={state.assignee_email || ''} rules={{required: t('management.certificates.tabsGeneralParameters.requiredField'), ...emailRule}}
                                       isRequired/>
                </FormGroup>
            </Col>
            <Col xs={12} md={6}>
                <FormGroup>
                    <TextControllerRHF inputName={'assignee_name'} control={control} setValue={setFormValue} disabled={isEditDisabled}
                                       placeholder={t('management.certificates.tabsGeneralParameters.recipientNamePlaceholder')}
                                       errors={errors} label={t('management.certificates.tabsGeneralParameters.recipientName')}
                                       defaultValue={state.assignee_name || ''} rules={{required: t('management.certificates.tabsGeneralParameters.requiredField')}}
                                       isRequired/>
                </FormGroup>
            </Col>
            <Col xs={12} md={6}>
                <FormGroup>
                    <TextControllerRHF inputName={'assignee_surname'} control={control} isRequired errors={errors} disabled={isEditDisabled}
                                       setValue={setFormValue} defaultValue={state.assignee_surname || ''}
                                       label={t('management.certificates.tabsGeneralParameters.recipientSurname')}
                                       placeholder={t('management.certificates.tabsGeneralParameters.recipientSurnamePlaceholder')}
                                       rules={{required: t('management.certificates.tabsGeneralParameters.requiredField')}}/>
                </FormGroup>
            </Col>
            <Col xs={12} md={6}>
                <FormGroup className="input_assigneePhone">
                    <PhoneRHF form={{control, errors}} state={state} setValue={setFormValue} disabled={isEditDisabled}
                              name={'assignee_phone'} isRequired country={getCountryCode()} className="form-control"
                              label={t('management.certificates.tabsGeneralParameters.recipientPhone')}/>

                </FormGroup>
            </Col>
            <Col xs={12} md={6}>
                <FormGroup>
                    <SelectControllerRHF options={getProductOptions(language)} inputName={'product_type'} control={control}
                                         setValue={setFormValue} errors={errors} rules={{required: t('management.certificates.tabsGeneralParameters.requiredField')}}
                                         label={t('management.certificates.tabsGeneralParameters.productType')} isRequired defaultValue={PRODUCT_TYPES.UNIVERSAL}
                                         disabled={isEditDisabled || state.type === 'corporate'}
                    />
                </FormGroup>
            </Col>
            <Col xs={12} md={6}>
                <FormGroup>
                    <SelectControllerRHF options={getCertificateTypes(language)} inputName={'type'} control={control} disabled={isEditDisabled}
                                         setValue={setFormValue} errors={errors} rules={{required: t('management.certificates.tabsGeneralParameters.requiredField')}}
                                         label={t('management.certificates.tabsGeneralParameters.certificateType')} isRequired defaultValue={state.type || 'default'}/>
                </FormGroup>
            </Col>
            <Col xs={12}>
                <Label>
                    {t('management.certificates.tabsGeneralParameters.activityDate')}
                </Label>
                <FormGroup>
                    <RangedDatePicker daysFilter={daysFilter} setDaysFilter={setDaysFilter} dateFormat={"DD.MM.YYYY"} disableTo={isEditDisabled} disableFrom={isEditDisabled}/>
                </FormGroup>
            </Col>
            {!state.id && <>
                <Col xs={12} md={6}>
                    <FormGroup>
                        <SelectControllerRHF options={getCountriesOptions()} inputName={'country'} control={control} disabled={isEditDisabled}
                                             setValue={setFormValue} errors={errors}
                                             rules={{required: t('management.certificates.tabsGeneralParameters.requiredField')}}
                                             label={t('management.certificates.tabsGeneralParameters.countryOfApplication')} isRequired defaultValue={defaultCountry}/>
                    </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                    <FormGroup>
                        <PriceCurrencyRHF form={form} state={state} isRequired setState={setFormValue} disabled={isEditDisabled}/>
                    </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                    <FormGroup>
                        <TextControllerRHF inputName={'size'} control={control} isRequired errors={errors}
                                           setValue={setFormValue} defaultValue={state.size || 0} disabled={isEditDisabled}
                                           label={t('management.certificates.tabsGeneralParameters.nominalValue')}
                                           placeholder={'5000'}
                                           rules={{
                                               ...onlyNumberRequired,
                                                 min: {
                                                    value: 1,
                                                    message: t('management.certificates.tabsGeneralParameters.minError')
                                                 }
                                            }}
                        />
                    </FormGroup>
                </Col>
                <Col xs={6}>
                    <FormGroup>
                        <TextControllerRHF inputName={'manager_comment'} control={control}
                                           label={t('management.certificates.tabsGeneralParameters.managerComment')}
                                           setValue={setFormValue} errors={errors} disabled={isEditDisabled}
                                           placeholder={t('management.certificates.tabsGeneralParameters.managerCommentPlaceholder')}
                                           rules={{
                                               maxLength: {
                                                   value: 255,
                                                   message: t('management.certificates.tabsGeneralParameters.commentMaxLength', {count: 255})
                                               }
                                           }}/>
                    </FormGroup>
                </Col>
            </>}
            <Col xs={6}>
                <FormGroup>
                    <TextControllerRHF inputName={'senders_email'} control={control} setValue={setFormValue} disabled={isEditDisabled}
                                       placeholder={'username@email.com'} errors={errors} label={t('management.certificates.tabsGeneralParameters.buyerMail')}
                                       defaultValue={state.senders_email || ''} rules={{required: t('management.certificates.tabsGeneralParameters.requiredField'), ...emailRule}}
                                       isRequired/>
                    {!state.id && <FormText>{t('management.certificates.tabsGeneralParameters.realMail')}</FormText>}
                </FormGroup>
            </Col>
            <Col xs={6}>
                <FormGroup>
                    <TextControllerRHF inputName={'assignee_comment'} control={control} label={t('management.certificates.tabsGeneralParameters.comment')}
                                       hint={t('management.certificates.tabsGeneralParameters.commentHint')} setValue={setFormValue}
                                       defaultValue={state.assignee_comment || ''} disabled={isEditDisabled}
                                       placeholder={t('management.certificates.tabsGeneralParameters.commentPlaceholder')} errors={errors}
                                       rules={{maxLength: {value: 45, message: t('management.certificates.tabsGeneralParameters.commentMaxLength', {count: 45})}}}/>
                </FormGroup>
            </Col>
        </Row>
    );
};

Parameters.propTypes = {
    state: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired
};

export default Parameters;