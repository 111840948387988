import React from 'react';
import { FormFeedback, FormText, Input, Label, PopoverBody, UncontrolledPopover } from 'reactstrap';
import PropTypes from 'prop-types';
import { FaInfoCircle } from 'react-icons/fa';
import get from 'lodash/get';

const FieldWithValidation = ({invalid, errors, label, name, children, hint, isRequired, info, ...rest}) => {
    const e = get(errors, `${name}.message`);
    return (
        <>
            {label && <Label>
                {label}{isRequired && <span style={{color: 'red'}}>*</span>}
              {Boolean(info) &&
                <>
                  <FaInfoCircle id={`${name}_fieldWithValidationIcon`} className="ml-1"/>
                  <UncontrolledPopover placement="right" target={`${name}_fieldWithValidationIcon`} trigger={'hover'}>
                    <PopoverBody>
                      {info}
                    </PopoverBody>
                  </UncontrolledPopover>
                </>
              }
            </Label>}
            {children ? children : <Input invalid={invalid} name={name} id={name} {...rest}/>}
            <FormFeedback>{invalid && e ? e : 'Ошибка валидации'}</FormFeedback>
            {hint && <FormText>{hint}</FormText>}
        </>
    );
}

FieldWithValidation.propTypes = {
    invalid: PropTypes.bool,
    isRequired: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    errors: PropTypes.object,
    hint: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string
};

export default FieldWithValidation;

