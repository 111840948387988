import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { t } from 'i18next';
import FieldWithValidation from '../../../generic/fields/FieldWithValidation';
import React from 'react';
import { Controller } from 'react-hook-form';
import RangeSelectorDatePicker from '../RangeSelectorDatePicker';
import { delta } from './helpers';
import {TimeZonePickerRHF} from "../../reactHookFormsFields";

const EditModalChildren = ({ entity, handleInput, control, errors, setEntity }) => {
  return <Form>
      <Row>
        <Col lg={12}>
          <FormGroup>
            <Label>{t('globalSales.form.title')}</Label>
            <Input
              type="text"
              name="title"
              id="title"
              placeholder={t("globalSales.form.title")}
              value={entity.title || ''}
              onChange={handleInput}
            />
          </FormGroup>
        </Col>
        <RangeSelectorDatePicker
          title={t('globalSales.form.range')}
          startKey='start_dateonly'
          endKey='end_dateonly'
          setEntity={setEntity}
          entity={entity}
          disabledDays={{ before: new Date() }}
        />
        <Col lg={6}>
          <FormGroup>
            <TimeZonePickerRHF form={{control, errors}} setState={e => handleInput(e, setEntity)}
                               state={entity}/>
          </FormGroup>
        </Col>
        <Col lg={6}>
          <FormGroup>
            <Controller
              control={control}
              name="start_time"
              defaultValue={entity.start_time || ''}
              rules={{required: t('inputs.validation.required')}}
              render={(
                {value, onBlur, name, ref, onChange},
                {invalid}
              ) =>
                <FieldWithValidation
                  invalid={invalid}
                  errors={errors}
                  label={t('globalSales.form.startTime')}
                  name={name}
                  isRequired={true}
                  type="time"
                  onBlur={onBlur}
                  value={value}
                  onChange={e => onChange(handleInput(e, setEntity).value)}
                />
              }
            />
          </FormGroup>
        </Col>
        <Col lg={6}>
          <FormGroup>
            <Controller
              control={control}
              name="end_time"
              defaultValue={entity.end_time || ''}
              rules={{required: t('inputs.validation.required')}}
              render={(
                {value, onBlur, name, ref, onChange},
                {invalid}
              ) =>
                <FieldWithValidation
                  invalid={invalid}
                  errors={errors}
                  label={t('globalSales.form.endTime')}
                  name={name}
                  isRequired={true}
                  type="time"
                  onBlur={onBlur}
                  value={value}
                  onChange={e => onChange(handleInput(e, setEntity).value)}
                />
              }
            />
          </FormGroup>
        </Col>
        <Col lg={6}>
          <FormGroup>
            <Controller
              control={control}
              name="count"
              defaultValue={entity.count || ''}
              rules={{min: { value: 0, message: t('inputs.validation.minNum', { count: 0 }) }}}
              render={(
                {value, onBlur, name, ref, onChange},
                {invalid}
              ) =>
                <FieldWithValidation
                  invalid={invalid}
                  errors={errors}
                  label={t('globalSales.form.count')}
                  name={name}
                  isRequired={true}
                  type="number"
                  onBlur={onBlur}
                  value={value}
                  onChange={e => onChange(handleInput(e, setEntity).value)}
                />
              }
            />
          </FormGroup>
        </Col>
        <Col lg={6}>
          <FormGroup>
            <Label>{t('globalSales.form.discountedPrice')}</Label>
            <Input
              type="number"
              name="discounted_price"
              id="discounted_price"
              placeholder={t("globalSales.form.discountedPrice")}
              value={entity.discounted_price || ''}
              onChange={handleInput}
            />
          </FormGroup>
        </Col>
        <Col lg={6}>
          <FormGroup>
            <Label>{t('globalSales.form.limit')}</Label>
            <Input
              disabled
              type="number"
              id="limit"
              placeholder={t("globalSales.form.limit")}
              value={entity.limit || entity.count}
              readOnly
            />
          </FormGroup>
        </Col>
        <Col lg={6}>
          <FormGroup>
            <Label>{t('globalSales.form.sold')}</Label>
            <Input
              disabled
              type="number"
              id="sold"
              placeholder={t("globalSales.form.sold")}
              value={delta(entity.limit || entity.count, entity.count)}
              readOnly
            />
          </FormGroup>
        </Col>
      </Row>
    </Form>
}

export default EditModalChildren;